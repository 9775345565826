import { useParams, useLocation } from "@remix-run/react";
import { useEffect, useRef } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useTypedFetcher } from "remix-typedjson";
import LoadingButton from "~/components/ui/buttons/LoadingButton";
import InputText, { RefInputText } from "~/components/ui/input/InputText";
import { useAppOrAdminData } from "~/utils/data/useAppOrAdminData";
import { useRootData } from "~/utils/data/useRootData";

interface Props {
  type: "anonymous" | "user";
  onClose: () => void;
}
export default function FeedbackForm({ type, onClose }: Props) {
  const { t } = useTranslation();
  const params = useParams();
  const appOrAdminData = useAppOrAdminData();
  const rootData = useRootData();
  const fetcher = useTypedFetcher<{ success?: string; error?: string }>();
  const location = useLocation();

  const refEmail = useRef<RefInputText>(null);
  const refMessage = useRef<RefInputText>(null);

  useEffect(() => {
    setTimeout(() => {
      if (rootData.user?.email) {
        refMessage.current?.textArea.current?.focus();
      } else {
        refEmail.current?.input.current?.focus();
      }
    }, 100);
  }, [rootData.user?.email]);

  useEffect(() => {
    if (fetcher.data?.success) {
      toast.success(fetcher.data.success);
      onClose();
    } else if (fetcher.data?.error) {
      toast.error(fetcher.data.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetcher.data]);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    fetcher.submit(e.currentTarget, {
      method: "post",
      action: type === "user" ? `/app/${params.tenant}` : "/api/feedback",
    });
    // onClose();
  };
  return (
    <fetcher.Form onSubmit={onSubmit} method="post">
      <input type="hidden" name="action" value="add-feedback" readOnly hidden />
      <input type="hidden" name="fromUrl" value={location.pathname} readOnly hidden />

      {type === "user" && <input type="hidden" name="tenantId" value={appOrAdminData.currentTenant?.id} readOnly hidden />}
      <div className="space-y-3">
        <h3 className="text-lg font-medium leading-6">{t("feedback.title")}</h3>
        <p className="text-muted-foreground text-sm">{t("feedback.description")}</p>

        <div>
          <InputText
            ref={refEmail}
            title={t("front.contact.email")}
            required
            id="email"
            name="email"
            type="email"
            autoComplete="email"
            defaultValue={rootData.user?.email || ""}
            readOnly={rootData.user?.email ? true : false}
          />
        </div>
        <div className="flex items-center space-x-2">
          <div>
            <InputText
              title={t("front.contact.firstName")}
              required
              type="text"
              name="firstName"
              id="firstName"
              autoComplete="given-name"
              defaultValue={rootData.user?.firstName || ""}
              readOnly={rootData.user?.firstName ? true : false}
            />
          </div>
          <div>
            <InputText
              title={t("front.contact.lastName")}
              type="text"
              name="lastName"
              id="lastName"
              autoComplete="family-name"
              defaultValue={rootData.user?.lastName || ""}
              readOnly={rootData.user?.lastName ? true : false}
            />
          </div>
        </div>

        <InputText ref={refMessage} name="message" defaultValue="" required rows={5} placeholder={t("feedback.placeholder")} />
        <div className="flex justify-between">
          <button type="button" onClick={onClose} className="text-muted-foreground text-sm hover:underline">
            {t("shared.cancel")}
          </button>
          <LoadingButton type="submit" isLoading={fetcher.state === "submitting"}>
            {t("feedback.send")}
          </LoadingButton>
        </div>
      </div>
    </fetcher.Form>
  );
}
